import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, TextField, Button, Checkbox } from '@mui/material'

import axios from 'axios';
import myImage from '../assets/icon-72x72.png'
import { AppContext  } from '../context/AppContext';


export const Requestor = () => {
  // Access vars and setVars from your contexts
  const { vars, setVars } = useContext(AppContext);

  const [myAgent, setMyAgent] = useState([]);

  const [rname, setRname] = useState('');
  const [rphone, setRphone] = useState('');
  const [remail, setRemail] = useState('');
  const [canPhoneChecked, setCanPhoneChecked] = useState(false);
  const [canEmailChecked, setCanEmailChecked] = useState(false); 


  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [goButtonGoLit, setGoButtonGoLit] = useState('');




  // ========================================================================================================  
// Step 3, 4 & 5: Conditional Fetch Call

useEffect(() => {
  console.log(vars)
  if (vars.npn) {
    // Fetch data from server
    const fetchProfile = async () => {
      const BASE_URL = 'https://sea-lion-app-6ajbn.ondigitalocean.app';
      const getUrl = `/agentbynpn/${vars.npn}`
      const urlProfile = BASE_URL+getUrl
      try {
        const response = await fetch(urlProfile);
        const data = await response.json();
        setMyAgent(data); // Step 7
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchProfile();
  }
}, [vars.npn, vars]); // Dependency array ensures this runs only when npn changes

// ======================================================================================================== 

const validateEmail = (email) => {
  // Simple regex for email validation
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
};

// ======================================================================================================== 
  // Step 5: Handle Changes
 

const validatePhoneNumber = (number) => {
  // Simple regex for phone number validation
  const regex = /^\+?([0-9]{1,3})?[-. ]?(\([0-9]{1,3}\)|[0-9]{1,3})[-. ]?[0-9]{1,4}[-. ]?[0-9]{1,4}[-. ]?[0-9]{1,4}$/;
  return regex.test(number);
};

useEffect(() => {
  // Update the context only if npn is not null or undefined
  if (rname) {
    setVars(prevVars => ({
      ...prevVars,
      rname: rname
    }));
  }
}, [rname, setVars]);

useEffect(() => {
  // Update the context only if npn is not null or undefined
  if (rphone) {
    setVars(prevVars => ({
      ...prevVars,
      rphone: rphone
    }));
  }
}, [rphone, setVars]);

useEffect(() => {
  // Update the context only if npn is not null or undefined
  if (remail) {
    setVars(prevVars => ({
      ...prevVars,
      remail: remail
    }));
  }
}, [remail, setVars]);

useEffect(() => {
  // Update the context only if npn is not null or undefined
  if (canPhoneChecked) {
    setVars(prevVars => ({
      ...prevVars,
      canphone: canPhoneChecked
    }));
  }
}, [canPhoneChecked, setVars]);

useEffect(() => {
  // Update the context only if npn is not null or undefined
  if (canEmailChecked) {
    setVars(prevVars => ({
      ...prevVars,
      canemail: canEmailChecked
    }));
  }
}, [canEmailChecked, setVars]);

const recordRequest = async (e) => {

  const record = {
    username: vars.username,
    npn: vars.npn,
    zipcode: vars.zipcode,
    countyfips: vars.countyfips,
    countyname: vars.countyname,
    statecode: vars.statecode,
    name: vars.rname,
    phone: vars.rphone,
    email: vars.remail,
    canphone: vars.canphone,
    canemail: vars.canemail
  }
  console.log('Record=',record)

  try {
    const url = 'https://sea-lion-app-6ajbn.ondigitalocean.app/tpmo/request'
    const response = await axios.post(url, record, 
      {
        headers: { 'Content-Type': 'application/json' }, 
        withCredentials: true
      }
    );
  } catch (err) {
    if (!err?.response) {
      console.log('No Server Response');
    } else {
      console.log('Registration Failed')
    }
  }
}
// ========================================================================================================  

// ========================================================================================================  

// ========================================================================================================  
const navigate = useNavigate();
const goButtonGo = () => {
  setGoButtonGoLit('<Accept and Request>')
  console.log(goButtonGoLit)

  recordRequest()
  //emailRequest()
  navigate(`/results`);
}

// ========================================================================================================  
// Page starts here
  return (
    <div className="container">
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <img className='logo-stuff' width='72' src={myImage} alt="My Description" style={{ marginRight: '10px' }} />
    <h3>TPMO Compliance Report</h3>
  </div>
  <p>A TPMO Compliance Report is being generated for agent {myAgent.firstname} {myAgent.lastname} serving {vars.countyname} County.  </p>
  <p>While it is being generated please enter your name and email address and it will be displayed to you when ready.</p>
  <p>We respect your privacy. If you prefer to not share  your prsonal information, just keep these fields blank and we will simply display it for you.</p>

  <h3>Your Name:</h3>
  <TextField
    type="text"
    value={rname}
    onChange={(e) => setRname(e.target.value)}
   variant='standard' sx={{width:300}}
    placeholder="Please enter your name"
    />

  <br/> 

  <br/>
  <h3>Your Phone Number:</h3>
  <TextField
    type="tel"
    value={rphone}
    onChange={(e) => setRphone(e.target.value)}
    error={phoneError}
    helperText={phoneError ? "Please enter a valid phone number" : ""}
     variant='standard' sx={{width:300}}
    placeholder="Please enter your phone number"
    />
    <br/>
    <Checkbox 
      value={canPhoneChecked}
      onChange={()=> setCanPhoneChecked( canPhoneChecked=> !canPhoneChecked)}
    />
    {canPhoneChecked ? "Yes, Please call me" : "No, Please do not call me "}

    <br/> 

    <br/>
    <h3>Your Email Address:</h3>
    <TextField
      variant='standard' sx={{width:300}}
      value={remail}
      onChange={(e) => setRemail(e.target.value)}
      error={emailError}
      type="email"
      helperText={emailError ? "Please enter a valid email address" : ""}
      placeholder="Please enter your email address"
      />
      <br/>
      <Checkbox 
      value={canEmailChecked}
      onChange={()=> setCanEmailChecked( canEmailChecked=> !canEmailChecked)}
      />
      {canEmailChecked ? "Yes, Please email me" : "No, Please do not email me "}

      <Stack spacing={2}>
      <br />
      <Button 
        onClick={() => goButtonGo()}
        type='submit' 
        variant='contained'>
        Accept and Request
        </Button> 
  </Stack>

    <br/> 
    <div>{vars.username}, {vars.npn}, {vars.countyfips}, {vars.statecode}, {vars.countyname}</div>
    <div>{rname}, {rphone}, {remail}</div>
    <div>{goButtonGoLit}</div>
    </div>
   
  )
}

export default Requestor;