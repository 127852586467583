import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useParams  } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

import MyLocalImage from '../assets/starLogo-128.jpg'
import { AppContext  } from '../context/AppContext';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    color: '#1C315E',
    padding: 10,
    alignItems: 'flex-start', // Align items to the start (left edge) of the page
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center', // Align items in the container center
    marginBottom: 10,
    marginLeft: 40,
    marginTop: 20,
  },
  titleText: {
    color: '#1C315E',
    fontSize: 18,
    fontWeight: 'bold',
    // Other text styles
  },
  titleImage: {
    width: 50, // Set the width of the image
    height: 50, // Set the height of the image
    marginRight: 10, // Add some margin if needed
  },  
  textLine: {
    marginBottom: 0,
    fontSize: 10,
    marginLeft: 40,
  },
  headerText: {
    color: '#1C315E',
    margin: 10, // Adjust margins as needed
    fontSize: 18, // Adjust font size as needed
    textAlign: 'center', // Center align the text
    fontWeight: 'bold',
    marginLeft: 40,
  },
  header14: {
    color: '#1C315E',
    margin: 10, // Adjust margins as needed
    fontSize: 14, // Adjust font size as needed
    textAlign: 'center', // Center align the text
    fontWeight: 'bold',
    marginLeft: 40,
  },
  header12: {
    color: '#1C315E',
    margin: 10, // Adjust margins as needed
    fontSize: 12, // Adjust font size as needed
    textAlign: 'center', // Center align the text
    fontWeight: 'bold',
    marginLeft: 40,
  },
  image: {
    width: 72, // Adjust the width as needed
    height: 72, // Adjust the height as needed
    position: 'absolute', // Use absolute positioning
    top: 0, // Align to top
    left: 0, // Align to left
  },

  basicText: {
    color: '#1C315E',
    margin: 10, // Adjust margins as needed
    fontSize: 10, // Adjust font size as needed
    textAlign: 'left', // Center align the text
    marginLeft: 40,
  },
  table: { 
    display: 'table', 
    width: 'auto', 
    marginTop: 0, // Adjust this value to move the table down
    marginLeft: 40, // Left margin to move the table from the left edge
    marginRight: 25,
    marginBottom: 20,
    borderRightWidth: 0, 
    borderBottomWidth: 0,
  },
  tableRow: { 
    flexDirection: 'row',
  },
  tableCol1: { 
    width: '27%', 
    padding: 2, 
  },
  tableColOther: { 
    width: '8%', 
    padding: 2, 
  },
  tableCell: { 
    margin: 'auto', 
    fontSize: 10 
  },
  titleRow: {
    color: '#1C315E',
    backgroundColor: '#FFF'
  },  
  text: {
    fontSize: 10 // Set your desired font size here
  },
  title: {
    fontSize: 24 // Set your desired font size here
  },
  h2: {
    fontSize: 16 // Set your desired font size here
  },
  h3: {
    fontSize: 14 // Set your desired font size here
  },
	section: {
		flexGrow: 1,
		margin: 12,
		padding: 10,
	},
	viewer: {
		width: window.innerWidth, //the pdf viewer will take up all of the width and height
		height: window.innerHeight,
	  },
    body: {
      padding: 10,
      flexGrow: 1, // Allows content to fill the page, pushing the footer to the bottom
    },
    footer: {
      position: 'absolute', // Absolute positioning
      bottom: 10, // Distance from the bottom of the page
      left: 0,
      right: 0,
      textAlign: 'center', // Center the text
      color: '#1C315E',
      fontSize: 12, // Footer text size
    },  

});

export const Results = () => {
  const { vars, setVars } = useContext(AppContext);
  

//const { username, npn, CountyFIPS, StateCode, CountyName } = useParams();
const [myProfile, setMyProfile] = useState([]);
const [myUser, setMyUser] = useState([])
const [state, setState] = useState([]);
const [myCarriers, setMyCarriers] = useState([]);
const [othCarriers, setOthCarriers] = useState([]);
const [myTabulate, setMyTabulate] = useState([]);
const [myTabulateLoading, setMyTabulateLoading] = useState(true);

// Data for the table
const tableData = [
  { carrier: "Aetna Medicare", Total: "11", col3: "4", col4: "4", col5: "0", col6: "0", col7: "0", col8: "0", col9: "3" },
  { carrier: "BCBSAZ Health Choice", Total: "1", col3: "1", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "0" },
  { carrier: "Blue Cross Blue Shield of Arizona (AZ Blue)	", Total: "2", col3: "0", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "2" },
  { carrier: "Humana", Total: "15", col3: "3", col4: "9", col5: "0", col6: "0", col7: "0", col8: "0", col9: "3" },  
  { carrier: "Mutual of Omaha Rx", Total: "3", col3: "0", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "3" },
  { carrier: "UnitedHealthcare", Total: "11", col3: "3", col4: "5", col5: "0", col6: "0", col7: "0", col8: "0", col9: "3" },
  { carrier: "Wellcare", Total: "5", col3: "0", col4: "2", col5: "0", col6: "0", col7: "0", col8: "0", col9: "3" },
  { carrier: "Wellcare by Allwell", Total: "5", col3: "5", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "0" },  
  { carrier: "Wellpoint", Total: "1", col3: "1", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "0" },  
  // Temp until fetchCarriers()
];


const tableData2 = [
  { carrier: "Cigna", Total: "3", col3: "0", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "3" },
  { carrier: "Clear Spring Health", Total: "1", col3: "0", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "1" },
  { carrier: "Devoted Health", Total: "3", col3: "1", col4: "2", col5: "0", col6: "0", col7: "0", col8: "0", col9: "0" },
  { carrier: "Mercy Care Advantage", Total: "1", col3: "1", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "0" },  
  // Temp until fetchOthCarriers()
];

// ========================================================================================================  
// ========================================================================================================  

const urlMyCarriers = `https://sea-lion-app-6ajbn.ondigitalocean.app/agentcarrier/${vars.username}/${vars.statecode}`;
// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyCarriers = async () => {
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(urlMyCarriers);
    const data = await response.json();

    setMyCarriers(data); // Assuming data is in the expected format
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchMyCarriers - Error fetching data:', error);
  }
};

useEffect(() => {
  fetchMyCarriers()
  console.log('fetchMyCarriers',  myCarriers)
}, [])

// ========================================================================================================   
  let tabCount = 0
  let tabTotal = 0
  let someOffered = 'do not'; 
  if (myCarriers.length > 0 && myTabulate.length > 0) {
    myCarriers.forEach((b) => {
      myTabulate.forEach((t) => {
        if (b.carrier === t.Carrier) {
          t.match = true;
          tabCount = tabCount + 1;
          tabTotal = tabTotal + t.Total;
          //console.log('match', t);
        }
      });
    });
    console.log (myCarriers.length, myTabulate.length)
    someOffered = 'do not';
    if (myCarriers.length === myTabulate.length) {
      someOffered = 'do';
    }
  }
// ========================================================================================================  

const BASE_URL = 'https://sea-lion-app-6ajbn.ondigitalocean.app';

const uriTabulate= `https://walrus-app-nzaox.ondigitalocean.app/counts/getTabulateStCoCarrierPlans/${vars.countyfips}`;
// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchTabulate = async () => {
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyProfile: ',urlMyProfile);
    const response = await fetch(uriTabulate);
    const data = await response.json();

    setMyTabulate(data); // Assuming data is in the expected format
    setMyTabulateLoading(false)
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('fetchTabulate - Error fetching data:', error);
    setMyTabulateLoading(false)
  }
};

useEffect(() => {
  fetchTabulate()

  if (myTabulate)
  console.log('fetchTabulate',  myTabulate)
}, [vars.countyfips])



// Step 3, 4 & 5: Conditional Fetch Call user
// ========================================================================================================  
// ========================================================================================================  
const urlMyUser = `https://sea-lion-app-6ajbn.ondigitalocean.app/user/${vars.username}`;
// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyUser = async () => {
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyUser: ',urlMyUser);
    const response = await fetch(urlMyUser);
    const data = await response.json();

    setMyUser(data); // Assuming data is in the expected format
    console.log('Data: ', data)
  } catch (error) {
    console.error('fetchMyUser- Error fetching data:', error);
  }
};

useEffect(() => {
  fetchMyUser()
  if (myUser) 
    console.log('fetchMyUser',  myUser)
}, [vars.username])
// ========================================================================================================  
// ========================================================================================================  
// Step 3, 4 & 5: Conditional Fetch Call Agent Profile

const getProfileUrl = `/agentbynpn/${vars.npn}`
const urlProfile = BASE_URL+getProfileUrl
useEffect(() => {
  if (vars.npn) {
    // Fetch data from server
    const fetchMyProfile = async () => {
      try {
        const response = await fetch(urlProfile);
        const data = await response.json();
        setMyProfile(data); // Step 7
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchMyProfile();
  }
}, [vars.npn]); // Dependency array ensures this runs only when npn changes

//======================================================================================

function formatPhoneNumber(number) {
  if (number)
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, number.length)}`;
}
//formatPhoneNumber(myProfile.officephone)

//======================================================================================
  return (
    <PDFViewer style={{ width: "100%", height: "90vh" }}>>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="Letter" style={styles.page}>
        <View style={styles.titleContainer}>
          <Image
            style={styles.titleImage}
            src={MyLocalImage}  // Replace with your image path or URL
          />
          <Text style={styles.titleText}>TPMO  Compliance Report</Text><br/>
      
        </View>      
        <Text style={styles.header12}>Generated especially for: {vars.rname} {' '} {vars.rphone} {' '} {vars.remail} </Text>  
          <View >
          <Text style={styles.textLine}>Your Medicare Agent</Text>
          <Text style={styles.textLine}>{myProfile.firstname} {myProfile.lastname} | {myProfile.agency}</Text>
          <Text style={styles.textLine}>{myProfile.address} | {myProfile.address2} | {myProfile.city} | {myProfile.county} | {myProfile.zipcode}</Text>
          <Text style={styles.textLine}>{formatPhoneNumber(myProfile.officephone)} | {myUser.email} | {myProfile.npn}</Text>
          </View>        
          <View >
          <Text style={styles.basicText}>
          Government Required Disclaimer: “We {someOffered} offer every plan available in your
          area. Currently, we represent {tabCount} organizations which offer {tabTotal} products 
          in your area. Please contact Medicare.gov, 1-800-MEDICARE, or your local State Health 
          Insurance Program to get information on all of your options.”
           </Text>
           <Text style={styles.basicText}>
          We Offer a selection of quality organizations with a mix of plan types. This
         allows us to provide a plan that meets your needs. A plan that includes your doctors, 
         covers your medications and has the benefits most important to you. We review with you 
         the details of how the plan will work and assist with the enrollment process. Note:  Not every plan type is available in every
         county or state.
           </Text>
           <Text style={styles.basicText}>
           All plans are insured or covered by a Medicare Advantage organization with a 
           Medicare contract and/or a Medicare approved Part D sponsor. Enrollment in 
           the plan depends on the plan’s contract renewal with Medicare.”
           </Text>
          </View>        
          <View >
          <Text style={styles.header12}>Organizations Contracted for {vars.countyname} County {vars.statecode}</Text>
            <View style={styles.table}>
            {/* Title Row */}
            <View style={[styles.tableRow, styles.titleRow]}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Carrier</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>HMO</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>PPO</Text>
              </View>
              <View style={styles.tableColOther}>
              <Text style={styles.tableCell}>SNP</Text>
            </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>PFFS</Text>
              </View>    
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>Cost</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>MSA</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>MMP</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>PDP</Text>
              </View>                
            </View>
            {/* Data Rows */}
            {myTabulate
              .filter(row=>row.match !==undefined)
              .sort ((a,b) => a.Carrier > b.Carrier ?  1: -1 )
              .map((row, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{row.Carrier}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.Total}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.HMO}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.PPO}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.PFFS}</Text>
                </View>                 
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.PFFS}</Text>
                </View>            
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.Cost}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.MSA}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.MMP}</Text>
                </View>
                <View style={styles.tableColOther}>
                  <Text style={styles.tableCell}>{row.PDP}</Text>
                </View>              
              </View>
            ))}
          </View>
            {/* <Text style={styles.basicText}>The following information is on the agent information page only.</Text> */}
            <Text style={styles.header14}>Organizations not Contracted for {vars.countyname} County {vars.statecode}</Text>
            <Text style={styles.basicText}>
            For transparency purposes we are providing the following information:
            </Text>
            <View style={styles.table}>
            {/* Title Row */}
            <View style={[styles.tableRow, styles.titleRow]}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>Carrier</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>HMO</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>PPO</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>SNP</Text>
              </View>               
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>PFFS</Text>
              </View>    
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>Cost</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>MSA</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>MMP</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>PDP</Text>
              </View>                
            </View>
           {/* Data Rows */}
           {myTabulate
            .filter(t=>t.match===undefined)
            .sort ((a,b) => a.Carrier > b.Carrier ?  1: -1 )
            .map((t, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Text style={styles.tableCell}>{t.Carrier}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.Total}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.HMO}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.PPO}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.PFFS}</Text>
              </View>              
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.PFFS}</Text>
              </View>            
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.Cost}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.MSA}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.MMP}</Text>
              </View>
              <View style={styles.tableColOther}>
                <Text style={styles.tableCell}>{t.PDP}</Text>
              </View>              
            </View>
           ))}
         </View>
    
    
           
          </View>
          <Text style={styles.footer}>
          Copyright  {new Date().getFullYear()} Clinebell Ltd. All rights reserved. v1.0.7
          </Text>
                    

          <View style={styles.section}>
            <Text style={styles.text}>.</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default Results;